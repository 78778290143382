/* COLOURS */
:root {
  --main-color:#3b73ff;
  --bg-color:#282c34;
  --dark-color:#1e2026;
  --highlight-color:#fff56d;
  --text-color:#f4f4f4;
  --light-main-color: #3b73ff;
  --light-bg-color: #f4f4f4;
  --light-dark-color:#fff;
  --light-highlight-color: #1e2026;
  --light-text-color:#282c34;
}
body.light-mode {
  --main-color: var(--light-main-color);
  --bg-color: var(--light-bg-color);
  --dark-color: var(--light-dark-color);
  --highlight-color: var(--light-highlight-color);
  --text-color:var(--light-text-color);
}

/* BASE UI */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
body {
  background-color: var(--bg-color);
  font-family: 'DM Sans', Helvetica, sans-serif;
  height: 100%;
  width: 100%;
}
div,section,span,button,input,p,h1,h2,h3,h4 {
  text-align: center;
  max-width: 90vw!important;
  margin-left:auto!important;
  margin-right:auto!important;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color:  var(--text-color);
}
button,
input {
  margin-top: 5px;
  padding: 15px 30px 20px 30px;
  font-size: 30px;
  border: none;
  border-radius: 4px;
  letter-spacing: -1px;
}
input {
  margin-top: 20px;
  width: 360px;
}
#Welcome button,
#Welcome input,
button.repeatBtn,
button.letgoBtn {
  width: 420px;
}
#Welcome input {
  width: 360px;
}
button {
  cursor: pointer;
  font-weight: bold;
  background-color: #eb0067;
  color: white;
  transition: background-color 0.3s, opacity 0.3s;
}
button.inactive {
  opacity: 0.2;
  cursor: not-allowed;
}
button.repeatBtn {
  background-color: rgba(255,255,255,0.5);
  color: var(--bg-color);
}

h2 {
  font-size: 60px;
  margin-bottom: 0;
  color: var(--highlight-color  );
}
.sponsor {
  margin-top:60px;
}
.sponsor a {
  color: var(--highlight-color);
}
.madeBy,
.madeBy a,
a.resetLink {
  color: #666;
}
p {
  width: 650px;
  margin-left: auto;
  margin-right: auto;
  font-size: 22px;
}
p.sponsor,
p.madeBy,
a.resetLink {
  font-size: 17px;
}

/* APP ELEMENTS */
.appWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* SVG countdown circle */
#countdown {
  position: relative;
  margin: 0px auto 25px auto;
  height: 340px;
  width: 340px;
  text-align: center;
}
#countdown-number {
  color: white;
  display: inline-block;
  line-height: 340px;
  font-size: 100px;
}
svg {
  position: absolute;
  top: 0;
  right: 0;
  transform: rotateY(-180deg) rotateZ(-90deg);
  width: 350px;
  height: 350px;
}
svg circle {
  stroke-dasharray: 878px; /* Adjust based on circle's circumference */
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 8px;
  stroke: var(--highlight-color);
  fill: rgba(0,0,0,0.2);
}
.animate {
  animation: countdown 17s linear forwards;
}
@keyframes countdown {
  from {
    stroke-dashoffset: 878px;
  }
  to {
    stroke-dashoffset: 0px;
  }
}

.resetLink {
  position: fixed;
  bottom: 10px;
  right: 10px;
  font-size: 0.8rem;
  text-decoration: none;
}

/* RESPONSIVE */
@media screen and (max-width:700px) {
  h2 {
    line-height: 1em;
    font-size: 40px;
  }
  #Welcome button,
  #Welcome input,
  button.repeatBtn,
  button.letgoBtn {
    width: 370px;
  }
  #Welcome input {
    width: 300px;
  }
  p {
    width: 340px;
    font-size: 15px;
  }
  div,svg,p,h1,h2,h3,button,input,span,section,body,#countdown {
    margin: 5px auto;
  }
  h2 {
    margin: 20px auto;
  }
}